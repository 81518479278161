.checkout-list-group {
    border:1px solid rgba(0,0,0,.125);
    border-top:unset;
    
    }
.checkout-list-group.first-item {
    border-top:1px solid rgba(0,0,0,.125);
    border-bottom: unset;
    }

    .checkout-list-group li{
        border:unset;
    }
    .list-group-item{
        border-top:unset;
        border-bottom:unset ;
    }
    .checkout-list-group h6,
    .checkout-list-group strong,
    .checkout-list-group span
    {
        font-size: 0.9rem;
    }
    .list-group-item h6,
    .list-group-item strong,
    .list-group-item span
    {
        font-size: 0.9rem;
    }

.flex{
    display: flex;
}

.mb-5{
    margin-bottom: 5px;
}