
.checkcontainer{
    padding:50px 80px 0px;
}
.checkcontainer .heading{
    font-size: 36px;
    color: #000000;
    font-weight: 700;
    text-align: center;
}
.checkcontainer p{
    text-align:center;
    color: #829196 !important;
    font-size: 14px;
    margin-top: 20px;
}

.chfl1{
    display: flex;
}

.chfl1>div{

    
    min-height: 20vh;
}

.chfl1 .left{

    flex-basis: 70%;
    position: relative;
}

.chfl1 .right{

    flex-basis: 30%;
}

.myform{

    width: 100%;
    margin-top:50px;
}

.myform>div{
    display: flex;
    padding: 10px 51px;
    column-gap: 86px;
}

.myform .half
{
    width: 50%;
}

.myform .full{
    width: 100%;
}