.dashboardflex {
  display: flex;
  /* padding-top: 80px; */
  padding: 80px 10px 0px 10px;
}

.dashboardflex .leftdiv {
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dashboardflex .rightdiv {
  width: 70%;
}

/* .accountinfo {
} */

.leftdiv .card {
  width: 20vw;
  min-height: 300px;
  /* border: 1px solid gray; */
  border-radius: 5px;
}

.cardflex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.cardflex h1 {
  color: #007fff;
  margin: 10px 0px 20px;
  font-size: 40px;
}

.cardflex h3 {
  font-size: 32px;
  color: gray;
}

.payment {
  width: 100%;
  height: 50px;
  font-size: 14px;
  width: 100%;
  color: white;
  border-radius: 0px;
  font-weight: 700;
}

.add-line {
  display: block;
  margin-top: 30px;
  text-align: center;
  height: 70px;
  line-height: 70px;
  border-radius: 5px;
  border: 2px dashed #aab8bd;
  color: #4c606b;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 20px;
}

.add-line i {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #33cccc;
  text-align: center;
  line-height: 40px;
  text-align: center;
  color: white;
  margin: 13px 0 0 10px;
  border-radius: 5px;
  font-size: 28px;
  vertical-align: top;
}

.fields {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 16px 0px;
}

.fields h4 {
  color: gray;
  font-size: 14px;
}
.edit-button {
  background-color: #e8f7fa;
  color: #33cccc;
  border-color: #e8f7fa;
  font-size: 14px;
}
.edit-button:hover {
  background-color: #e8f7fa;
  color: #33cccc;
  border-color: #e8f7fa;
}
.save-button {
  background-color: #33cccc;
  color: white;
  border-color: #33cccc;
  font-size: 14px;
  width:60px;
  margin-top: 27px !important;
}
.save-button:hover {
  background-color: #33cccc;
  color: white;
  border-color: #33cccc;
}
.save-button:focus {
  background-color: #33cccc;
  color: white;
  border-color: #33cccc;
  box-shadow: none;
  width:60px;
}
.cancel-button {
    background-color: white;
    color: red;
    border-color: red;
    font-size: 14px;
    margin-top: 27px !important;
  }
  .cancel-button:hover {
    background-color: white;
    color: red;
    border-color: red;
  }
  .input-label {
    font-size: 14px;
    color:#aab8bd;
    font-weight: 700;
    margin-bottom: 5px;
  }

.autopay {
  background: #e8f7fa;
  display: inline-block;
  padding: 17px 30px 17px 17px;
  border-radius: 5px;
  margin: 10px 0px;
}

.autopay label {
  display: inline-block;
  font-size: 16px;
  color: #4c606b;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.4;
}

.addline {
  /* display: block; */
  margin-top: 30px;
  text-align: center;
  /* height: 70px; */
  line-height: 70px;
  border-radius: 5px;
  /* border: 2px dashed #aab8bd; */
  color: #2dafbb;
  text-decoration: none;
  font-weight: 400;
  text-transform: uppercase;
  /* margin-left: 40px; */
  font-size: 15px;
  cursor: pointer;
  padding: 17px 30px 17px 17px;
}

.mt-8 {
  margin-top: 10px !important;
}

.mygrid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 50px 0px;
}
.mygrid div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mygrid2 {
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.myplanflex {
  display: flex;
  justify-content: space-around;
  margin: 10px 0px;
}

.myplanflex1 {
  display: flex;
  column-gap: 25px;
 
}
.myplanflex1:first-child {
  padding-left: 30px;
}
.myplans {
  /* width: 33%; */
  width: 40%;
  padding: 10px;
}

.vdivider {
  border-left: 1px solid gainsboro;
}

.NA1 {
  color: #aab8bd;
  font-size: 14px;
  font-weight: 700;
}

.NA2 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 15px;
}

.NA2 button {
  background: rgba(51, 204, 204, 0.1);
  color: #33cccc;
  cursor: pointer;
  padding: 7px 15px;
  font-style: italic;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  margin-left: 10px;
}

.usages tr th {
  color: #aab8bd;
  font-size: 12px;
  font-weight: 700;
  display: table-cell;
  vertical-align: top;
  padding: 5px 7px;
  white-space: nowrap;
}

.voice-usage {
  border-right: 1px solid#d7dee0;
  border-left: 1px solid#d7dee0;
}

.usagesbtn {
  color: #fff;
  padding: 0 35px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 35px;
  background: #ff5622;
  background: -moz-linear-gradient(left, #ff0707 0%, #ff8181 100%);
  background: -webkit-linear-gradient(left, #ff0707 0%, #ff8181 100%);
  background: linear-gradient(left, #ff0707 0%, #ff8181 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5700b5', endColorstr='#ac00f6',GradientType=1 );
  border: 1px solid transparent;
  cursor: pointer;
}

.flexcenter {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-right: 30px;
}

.border-right {
  border-right: 1px solid#d7dee0;
}

.monthlybilling {
  opacity: 0.8;
}

.total tr td {
  color: #aab8bd;
  font-weight: normal;
  text-align: left;
  padding-right: 60px;
  padding: 10px;
}

.total .price {
  text-align: right;
  font-weight: 700;
  color: #4c606b;
  margin-top: 30px;
  font-size: 14px;
}

.editbill {
  font-style: italic;
  font-size: 14px;
  text-decoration: none;
  color: #33cccc;
  text-align: center;
  margin-bottom: 10px;
}
.editbill:hover{
  cursor: pointer;
  color: #23527c;
}

.mygrid h2 {
  display: table-cell;

  padding: 0px 0 20px;
  font-size: 36px;
  color: #4c606b;
  font-weight: 300;
  text-align: center;
}

.mygrid h4 {
  color: #aab8bd;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}

.chargemonth {
  font-size: 14px;
  color: #4c606b;

  line-height: 1.4;
  margin-bottom: 10px;
}

.chargemonth span {
  display: inline-block;
  background: rgba(51, 204, 204, 0.1);
  border-radius: 5px;
  padding: 5px 10px;
}

.edit {
  font-style: italic;
  text-decoration: none;
  color: #33cccc;
}
.edit:hover{
  cursor: pointer;
}

.cardsave {
  background: #33cccc;
  color: white;
  border: 1px solid #33cccc;
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  margin-left: 10px;
  margin: 20px 10px;
  width: 70px;
}
.cardsave:focus {
  width: 70px;
}

.cardcancel {
  background: white;
  color: red;
  border: 1px solid red;
  margin-left: 0;
  padding: 7px 15px;
  font-style: italic;
  font-size: 14px;
  border-radius: 5px;
  margin-left: 10px;
  display: inline-block;
  width: auto;
  height: 47px;
  line-height: normal;
}
.css-1lxkkuj{
  background: none !important;
  border: none !important;
}
.download_button{
  background: #e8f7fa;
  background: rgba(51, 204, 204, 0.1);
  padding: 7px 20px;
  display: inline-block;
  color: #33cccc;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  font-style: italic;
}
.download_button:hover{
  color: #33cccc;
}
.primary-card-text:hover {
cursor: default !important;
}

@media screen and (max-width: 960px) {

  .dashboardflex{
    flex-direction:column;
    align-items: center;
    gap:50px;
  }
  .dashboardflex .leftdiv{
    width: 70%;
  }
  .leftdiv .card {
    width: 100% !important;
  }
  .dashboardflex .rightdiv {
    width: 100%;
  }
  .dashboardflex .rightdiv >div {
    max-width: unset !important;
  }
}
.pure-material-progress-linear {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 0.38em;
    color: wheat;
    background-color: #f7780871;
  font-size: 16px;
  width: 100%;
  position: fixed;
  z-index: 1000;
}

.pure-material-progress-linear::-webkit-progress-bar {
  background-color: transparent;
}

/* Determinate */
.pure-material-progress-linear::-webkit-progress-value {
  background-color: currentColor;
  transition: all 0.2s;
}

.pure-material-progress-linear::-moz-progress-bar {
  background-color: currentColor;
  transition: all 0.2s;
}

.pure-material-progress-linear::-ms-fill {
  border: none;
  background-color: currentColor;
  transition: all 0.2s;
}

/* Indeterminate */
.pure-material-progress-linear:indeterminate {
  background-size: 200% 100%;
  background-image: linear-gradient(to right, #f7780871 50%, currentColor 50%, currentColor 60%, #f7780871 60%, #f7780871 71.5%, currentColor 71.5%, currentColor 84%, #f7780871 84%);
  animation: pure-material-progress-linear 2s infinite linear;
}

.pure-material-progress-linear:indeterminate::-moz-progress-bar {
  background-color: transparent;
}

.pure-material-progress-linear:indeterminate::-ms-fill {
  animation-name: none;
}

@keyframes pure-material-progress-linear {
  0% {
      background-size: 200% 100%;
      background-position: left -31.25% top 0%;
  }
  50% {
      background-size: 800% 100%;
      background-position: left -49% top 0%;
  }
  100% {
      background-size: 400% 100%;
      background-position: left -102% top 0%;
  }
}

@media screen and (max-width: 768px) {

  .monthlybilling .myplanflex{
    flex-direction: column;
  }
  .monthlybilling .myplanflex .myplans{
    
    width: 100%;
    border-right:unset;
    text-align: center;
  }
  .monthlybilling .myplanflex .flexcenter{
    margin-right: 0px;
  }
  .monthlybilling .myplanflex .myplanflex1{
    column-gap: 0;
    justify-content: center;
  }
  .monthlybilling .myplanflex .myplanflex1:first-child{
    position: relative;
    right: 20px;
    padding-left: 0;
  }
  .monthlybilling .mygrid{
    grid-template-columns: repeat(1, 1fr);
  }
}