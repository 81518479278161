:root {
  --primary: #fff;
}

.btnbtn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn--primary {
  background-color: var(--primary);
  color: black;
  border-radius: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}



.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 80px;
  text-decoration: none;
  font-size: 24px;
  background-color: transparent;
  color: #fff;
  padding: 14px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 17px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
}

.btn--wide:hover {
  color: #fff;
  background-color: #f00946;
  transition: all 0.2s ease-out;
}

.blue {
  background-color: #276afb;
  color: #fff;
  border: none;
}
.red {
  background-color: #f00946;
  color: #fff;
  border: none;
}
.primary{
    width: 105px;
    height: 41px;
    background: #152D79 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #769af5;
    border-radius: 36px;
    opacity: 0.97;
    color: white;
}


.primary:hover {
  background-color: #f77908;
  color: #fff;
  border: none;
}
.green {
    background-color: #25ce4a;
    color: #fff;
    border:none;
}
.green:hover{
    background-color: #242424;
}
.orange{
  background-color:#f77908;
  color: #fff;
  width: 100px;
  display: flex;
 justify-content: center;
  text-decoration: none;
  border-radius: 23px;
}

.orange:hover{
  background-color: #1c347d;

}