.crossbtn{
    border:none;
    background:none;
}
.thanks{
    color: #000000;
    max-width: 640px;
    font-weight: 700;
    margin:50px 0px;
}

.process{
    color: #829196 !important;
}

.clickhere{
    /* color:#829196 !important; */
    color: #000000 !important;
}

/* .email{
    color: #829196 !important;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 700;
} */
.email {
    color: #829196 !important;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    font-family: sans-serif;
    margin-bottom: 30px;
}
.detail .email{
    color: #fcfcfc !important;
}
.cartitems{

    border-top: 1px solid black;
    /* border-bottom: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.finalinvoice{
    width: 436px;
    /* position: relative; */
    right: 1;
    right: 239px;
    top: 15px;
}
