.footer-container {
  background-color: #122665;
    display: flex;
    height: 300px;
    justify-content: space-around;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}
.footer-logo{
  margin-top: 77px;

}

.footer-input {
  padding: 8px 24px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  display: flex;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  margin: 11px;
  text-align: left;
  width: 174px;
  /* box-sizing: border-box; */
  margin-top: 28%;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
  text-transform: capitalize;
    opacity: 1;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  /* width: 100%; */
  display: flex;
}



/* .social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
} */

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: -15px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

.number{
  color: rgb(255, 255, 255);
  width: 301px;
  height: 57px;
  height: 27px;
  font: normal normal 500 20px/30px Nunito Sans;
  opacity: 1;
  color: #FCFCFC;
  text-transform: capitalize;
}
.email{
  text-align: left;
    font: normal normal 500 20px/30px Nunito Sans;
    letter-spacing: 0px;
    color: #FCFCFC;
    text-transform: capitalize;
}
.social-media-wrap{
  display: flex;
    justify-content: center;
}
.footer-links{
  display: flex;
  justify-content: center;
  margin-top: -4%;
  margin-left: 7%;
}


.hq1{
  width: 47px;
height: 32px;
text-align: left;
font: normal normal 700 24px/35px Nunito Sans;
letter-spacing: 0px;
color: #FCFCFC;
text-transform: lowercase;
}
.hq2{
  width: 189px;
  height: 37px;
  font: normal normal 500 17px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #FCFCFC;
  text-transform: capitalize;
  opacity: 1;
}
.ft1{
  background: #F5F7FF 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  gap: 10px;
  color: #152D79;
  font-size: 14px;
  line-height: 20px;
}
/* .trans-foot{
  background-color: #122665;
  width: 100vw;
  
} */


@media only screen and (max-width: 990px) {

.trans-foot{
  transform: scale(0.8) !important;
}
.footer-link-wrapper {
  display: flex;
  /* width: 100vw; */
  transform: scale(0.7);
}
.footer-link-items {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* margin: 11px; */
  /* text-align: left; */
  /* width: 174px; */
  /* box-sizing: border-box; */
  margin-top: 15% !important;
}
.footer-links {
  display: flex;
  justify-content: center;
  margin-top: 0% !important; 
  margin-left: 0% !important; 
  width: 45%  !important;

}
.footer-logo {
  margin-top: 41px !important;
}
.footer-container {
  background-color: #122665;
  display: flex;
  height: 244px !important;
  justify-content: space-around;
}
}

@media only screen and (max-width: 768px) {

  .footer-logo{
    transform: scale(0.7);
  }
  .footer-link-wrapper{
    transform: scale(0.6);
  }
}

@media only screen and (max-width: 650px) {
  .footer-logo{
    transform: scale(0.6);
  }
  .footer-link-wrapper{
    transform: scale(0.5) !important;
    margin-right: 64px;
  }
}
@media only screen and (max-width: 480px) {
  .footer-container {
    /* background-color: #122665; */
    /* display: flex; */
    height: unset !important;
    /* justify-content: space-around; */
    flex-direction: column;
}

.footer-link-wrapper {
  transform: unset !important;
  margin-right: 0px !important;
  width: 100%;
  justify-content: space-around;

}

.social-media-wrap {
  /* display: flex;
  justify-content: center; */
  margin-bottom: 0px !important;
}

.footer-links {
  /* display: flex; */
  justify-content: center;
  margin-top: 0% !important;
  margin-left: 0% !important;
  width: 100% !important;

}
.hq1 {
  text-align: center;
  font: normal normal 500 15px/35px Nunito Sans !important;
  width: fit-content;
}
.hq2 {
  font-size: 12px !important;
  text-align: left;
  display: flex;
  letter-spacing: 0px;
  color: #FCFCFC;
  width: 110px !important;
  height: 37px !important;
  justify-content: center;
  font-weight: 500;
}
.footer-link-items {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  width: 92px !important;
  /* text-align: center; */
  /* margin-top: 49% !important; */
  align-items: center;
}
.footer-logo{
  transform: unset !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px !important;
}
.footer-logo .detail h3{
  text-align: center;
}
}