.im86.support {
  width: 53%;
  position: absolute;
  transform: translate(227px, -13px);
}

.support-tetx.support {
  background-color: #fff !important;
}

.dial {
  display: flex;
  justify-content: center;
  background-color: #f5f7ff;
  padding-top: 10%;
  padding-bottom: 3%;
  position: relative;
}
.dial-text {
  display: flex;
  justify-content: center;
  background-color: #f5f7ff;
  padding-top: 5%;
  padding-bottom: 2%;
  position: relative;
  font: normal normal 800 16px/0px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
}
.section3-text {
  display: flex;
  justify-content: center;
  background-color: #ffff;
  margin-top: 33px;
  min-height: 100px;
}
.dial img {
  width: 31%;
  z-index: 1;
}
.btn-sec3 {
  width: 157px;
  height: 39px;
  background: #152d79 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 34px;
  border: none;
  opacity: 1;
  color: #ffff;
  font-family: Nunito Sans;
}

.dial .line {
  position: absolute;
  width: 70%;
  border-bottom: 1px solid #a6bcf5;
  /* z-index: 98; */
  color: #18317b;
}
.btn-secdiv {
  display: flex;
  justify-content: center;
  /* margin-top: -33px; */
  margin-bottom: 32px;
}

.line.pos1 {
  margin-top: 3%;
  display: flex;
  justify-content: space-between;
  column-gap: 500px;
}
.section3-head {
  width: 437px;
  /* height: 111px; */
  text-align: center;
  font: normal normal bold 30px/36px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  text-transform: capitalize;
  opacity: 1;
}
.line.pos2 {
  margin-top: 13.5%;
  display: flex;
  justify-content: space-between;
  column-gap: 641px;
}

.line.pos3 {
  margin-top: 24.5%;
  display: flex;
  justify-content: space-between;
  column-gap: 500px;
}

.feature {
  position: absolute;
  display: flex;
}

.feature h2 {
  font: normal normal bold 30px/30px Nunito Sans;
  letter-spacing: 0px;
  color: #18317b;
}

.feature p {
  font: normal normal 600 20px/30px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
}

.feature1 {
  position: absolute;
  left: 15%;
  top: 13%;
  font-size: 1vw;
  width: 21vw;
  color: #18317b;
}

.feature2 {
  position: absolute;
  left: 64%;
  top: 13%;
  font-size: 1vw;
  width: 21vw;
  text-align: end;
  color: #18317b;
}

.feature3 {
  position: absolute;
  left: 15%;
  top: 39%;
  font-size: 1vw;
  width: 21vw;
  color: #18317b;
}

.feature4 {
  position: absolute;
  left: 15%;
  top: 64%;
  font-size: 1vw;
  width: 21vw;
  color: #18317b;
}
.feature5 {
  position: absolute;
  left: 64%;
  top: 39%;
  font-size: 1vw;
  width: 21vw;
  text-align: end;
  color: #18317b;
}
.feature6 {
  position: absolute;
  left: 64%;
  top: 62%;
  font-size: 1vw;
  width: 21vw;
  text-align: end;
  color: #18317b;
}

.section2support {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  padding: 86px;
  color: #152d79;
}
.section5support {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  padding: 86px;
  color: #152d79;
}

.section2support .line {
  border-left: 1px solid #a6bcf5;
}
.section5support .line {
  border-left: 1px solid #a6bcf5;
}

.section2support .basis {
  flex-basis: 26%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
}

.section5support .basis {
  flex-basis: 16%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
}
.emergency {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal 800 25px/40px Nunito Sans;
  letter-spacing: 0px;
  text-align: center;
  color: #152d79;
}

.emergency p {
  width: 34%;
  margin-bottom: 20px;
  text-align: center;
  font: normal normal 800 30px/38px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  margin-bottom: 34px;
}
.font-size {
  font: normal normal bold 19px/34px Nunito Sans;
}
.font-para {
  font: normal normal 500 16px/20px Nunito Sans;
}

.basis-head {
  text-align: left;
  font: normal normal bold 16px/20px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  text-transform: capitalize;
  opacity: 1;
}
.basis-para {
  width: 236px;
  /* height: 77px; */
  text-align: left;
  font: normal normal 600 15px/24px Nunito Sans;
  letter-spacing: 0px;
  color: #405ebf;
  opacity: 1;
}
.basis-head1 {
  text-align: left;
  font: normal normal bold 14px/34px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  text-transform: capitalize;
  opacity: 1;
}
.basis-para1 {
  text-align: left;
  font: normal normal 500 17px/26px Nunito Sans;
  letter-spacing: 0px;
  color: #405ebf;
  opacity: 1;
  height: 52px;
}

.basis img{
  height: 50px;
  object-fit: scale-down;
}
.section3-support {
    min-height: 515px;
  background: #f5f7ff 0% 0% no-repeat padding-box;
  opacity: 1;
}
.support3-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec3-head {
  width: 454px;
  text-align: center;
  font: normal normal bold 20px/32px Nunito Sans;
  letter-spacing: 0px;
  color: #405ebf;
  opacity: 1;
  margin-top: 40px;

}
.imges-sec3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
}
.img-1 {
  width: 171px;
  height: 169px;
}
.arrow-1 {
  width: 125px;
  margin-right: 42px;
  margin-bottom: 91px;

}
.head-sec3 {
  text-align: center;
  font: normal normal bold 19px/30px Nunito Sans;
  letter-spacing: 0px;
  color: #ee8b3f;
  text-transform: capitalize;
  opacity: 1;
}
.head-sec4{
    text-align: center;
    font: normal normal bold 14px/34px Nunito Sans;
    letter-spacing: 0px;
    color: #152D79;
    opacity: 1;
}
.para-sec4{
    text-align: center;
    letter-spacing: 0px;
    font: normal normal bold 9px/15px Nunito Sans;
    color: #405EBF;
    opacity: 1;
    width: 191px;
}
.bar-1{
    position: absolute;
    position: absolute;
    bottom: -13px;
    right: 5px;
}
.bar-2 {
    position: absolute;
    margin-top: 66px;
    height: 3px;
    margin-right: 262px;
}
.section3-head{
    position: relative;
}

.header-head{
    position: relative;
}



@media (max-width: 1116px) {
  .dial img {
    width: 28%;
    /* z-index: 99; */
}
.head-sec4 {
  text-align: center;
  font: normal normal bold 14px/40px Nunito Sans;
  letter-spacing: 0px;
  color: #152D79;
  opacity: 1;
}
.line.pos3 {
  margin-top: 22.5%;
  display: flex;
  justify-content: space-between;
  column-gap: 500px;
}
  .font-para {
    font: normal normal 500 12px/12px Nunito Sans ;
}
.font-size {
  font: normal normal bold 16px/29px Nunito Sans ;
}
}

@media (max-width: 960px) {
  .section2support{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
  }
  .emergency p {
    width: 79% !important;
    margin-bottom: 20px !important;
    text-align: center;
    font: normal normal 800 30px/38px Nunito Sans;
    letter-spacing: 0px;
    color: #152d79;
    margin-bottom: 34px;
}
}

@media (max-width: 950px){
  .section5support{
    transform: scale(0.8) !important;
   }

   .imges-sec3 {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-direction: column !important;
    margin-top: 26px !important;
}
.arrow-1{
  display: none;
}
.para-sec4{
  margin-bottom: 28px !important;
}
}

@media (max-width: 858px) {
  .font-para {
    font: normal normal 500 9px/11px Nunito Sans ;
}
.font-size {
  font: normal normal bold 12px/24px Nunito Sans ;
}
}

@media (max-width: 660px) {
 .section5support{
  transform: scale(0.7) !important;
 }
 .font-para {
  font: normal normal 500 7px/10px Nunito Sans ;
}
.font-size {
  font: normal normal bold 9px/10px Nunito Sans ;
}
.help-dial{
  padding: 10% 0;
}
/* .help-dial img{
  width: 152px;
  height: 132px;
} */
.help-dial .feature1,
.help-dial .feature3,
.help-dial .feature4{
  left: 2%;
  width: 30vw;
}
.help-dial .feature2,
.help-dial .feature5,
.help-dial .feature6{
  left: unset;
  right: 2%;
  width: 30vw;
}
.smart-dial .feature1,
.smart-dial .feature3,
.smart-dial .feature4{
  left: 3%;
  width: 30vw;
}
.smart-dial .feature2,
.smart-dial .feature5,
.smart-dial .feature6{
  left: unset;
  right: 3%;
  width: 30vw;
}
.help-dial .feature1,
.help-dial .feature2{
  width: 35vw;
}
.smart-dial .feature1,
.smart-dial .feature2{
  width: 35vw;
}
.smart-dial .feature3,
.smart-dial .feature5{
  width: 29vw;
}
.help-dial .line {
  width: 96%;
}
.smart-dial .line {
  width: 96%;
}
.help-dial .font-size{
 
 font: normal normal 800 12px/12px Nunito Sans !important;

 margin-bottom: 2px;
}
.help-dial .font-para{
  font: normal normal 600 9px/12px Nunito Sans !important
}
.smart-dial .font-size{
 
 font: normal normal 800 12px/12px Nunito Sans !important;

 margin-bottom: 5px;
}
.smart-dial .font-para{
  font: normal normal 600 9px/12px Nunito Sans !important
}
.help-dial .line.pos1 {
  margin-top: 5%;
}
.help-dial .line.pos2 {
  margin-top: 17.5%;
}
.help-dial .line.pos3 {
  margin-top: 31.5%;
}
.help-dial .feature3,
  .help-dial .feature5{
    top: 38%;
    width: 30vw;
  }
.help-dial .feature6,
  .help-dial .feature4{
    top: 66%;
  }
.smart-dial .feature6,
  .smart-dial .feature4{
    top: 64%;
  }
  .line.pos3{
    display: none;
  }
}

@media (max-width: 580px) {
  .section5support{
    transform: scale(0.6) !important;
    margin-bottom: -93px;
    margin-top: -71px;

   }
  }

  @media (max-width: 576px) {
    .bar-1{
      display: none;
    }
  }

  @media (max-width: 480px) {
    .section2support {
      flex-direction: column;
      row-gap: unset;
      padding: 40px 20px;
      align-items: center;
    }
    .section2support .basis{
      align-items: center;
    }
    .basis-para1{
      text-align: center;
    }
    .bar-2 {
      display: none;
  }
    .section5support {
      flex-direction: column;
      row-gap: 10px;
      transform: unset !important;
      margin: 0;
      align-items: center;
      padding: 40px 20px;

    }
    .section5support .basis{
      align-items: center;
    }
    .basis-para{
      text-align: center;
    }
    .emergency p {
      width: 70%;
      font-size: 4vw;
    }
    .emergency p {
      text-align: center;
      font: normal normal 800 17px/29px Nunito Sans !important;
      letter-spacing: 0px;
      color: #152d79;
      margin-bottom: 34px !important;
    }
    .font-para {
      /* font: normal normal 500 6px/8px Nunito Sans !important; */
      font: normal normal 500 5px/6px Nunito Sans !important;
    }
    .font-size {
      /* font: normal normal bold 7px/6px Nunito Sans !important; */
      font: normal normal bold 6px/6px Nunito Sans !important;
      margin-bottom: 2px;
      margin-top: 2px;
    }
    .sec3-head {
      width: 224px !important;
      text-align: center;
      font: normal normal bold 10px/32px Nunito Sans !important;
      letter-spacing: 0px;
      color: #405ebf;
      opacity: 1;
      margin-top: 40px !important;
  }
  .arrow-1 {
      display: none;
  }
  .imges-sec3 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 27px !important;
      flex-direction: column;
  }
  .para-sec4 {
      text-align: center;
      letter-spacing: 0px;
      font: normal normal bold 9px/15px Nunito Sans !important;
      color: #405EBF;
      opacity: 1;
      width: 191px !important;
      margin-bottom: 28px;
      align-items: center;
      text-align: center;
  }
  .dial-text {
      display: flex;
      justify-content: center;
      background-color: #f5f7ff;
      padding-top: 5% !important;
      padding-bottom: 7% !important;
      position: relative;
      font: normal normal 800 11px/1px Nunito Sans !important;
      letter-spacing: 0px;
      color: #152d79;
  }
  .section3-head {
      width: 419px !important;
      /* height: 111px; */
      text-align: center;
      font: normal normal bold 22px/28px Nunito Sans !important;
      letter-spacing: 0px;
      color: #152d79;
      text-transform: capitalize;
      opacity: 1;
  }
  .img-1 {
      width: 176px !important;
      height: 169px !important;
  }
  .feature6{
    top: 64%;
  }
  .help-dial{
    padding: 20% 0;
  }
  .smart-dial{
    padding: 20% 0;
  }
  .help-dial img{
    width: 152px;
    height: 132px;
  }
  .smart-dial img{
    width: 152px;
    height: 132px;
  }
  .help-dial .feature1,
  .help-dial .feature3,
  .help-dial .feature4{
    left: 2%;
    width: 30vw;
  }
  .help-dial .feature2,
  .help-dial .feature5,
  .help-dial .feature6{
    left: unset;
    right: 2%;
    width: 30vw;
  }
  .help-dial .feature1,
  .help-dial .feature2{
    width: 35vw;
  }
  .help-dial .line {
    width: 96%;
  }
  .help-dial .font-size{
   
   font: normal normal 800 12px/12px Nunito Sans !important
  }
  .smart-dial .font-size{
   
   font: normal normal 800 12px/12px Nunito Sans !important;
   margin-bottom: 0px;
  }
  .help-dial .font-para{
    font: normal normal 600 9px/12px Nunito Sans !important
  }
  .help-dial .line.pos1 {
    margin-top: 5%;
  }
  .help-dial .line.pos2 {
    margin-top: 25.5%;
  }
  .help-dial .line.pos3 {
    margin-top: 43.5%;
  }
  .smart-dial .line.pos1 {
    margin-top: 5%;
  }
  .smart-dial .line.pos2 {
    margin-top: 22.5%;
  }
  .smart-dial .line.pos3 {
    margin-top: 42.5%;
  }
  .help-dial .feature3,
    .help-dial .feature5{
      top: 37%;
      width: 30vw;
    }
  .help-dial .feature6,
    .help-dial .feature4{
      top: 66%;
    }
  }
  @media (max-width: 401px) {
    .help-dial .feature1,
    .help-dial .feature2{
      top: 13%;
      width: 35vw;
    }
    .help-dial .feature3,
    .help-dial .feature5{
      top: 35%;
      width: 30vw;
    }
    .help-dial .feature6,
    .help-dial .feature4{
      top: 68%;
      width: 35vw;
    }
    .help-dial .line.pos2 {
      margin-top: 29.5%;
    }
    .help-dial .line.pos3 {
      margin-top: 49.5%;
    }
  }
  @media (max-width: 375px) {

    .help-dial img, .smart-dial img {
    height: 126px;
    width: 142px;
}
    .help-dial .feature1,
    .help-dial .feature2{
      top: 10%;
      width: 35vw;
    }
    .help-dial .feature3,
    .help-dial .feature5{
      top: 35%;
      width: 30vw;
    }
    .help-dial .feature6,
    .help-dial .feature4{
      top: 70%;
      width: 35vw;
    }
    .help-dial .line.pos2 {
      margin-top: 31.5%;
    }
    .help-dial .line.pos3 {
      margin-top: 50.5%;
    }
  }
  @media (max-width: 350px) {
    .font-para {
      
      font: normal normal 500 5px/5px Nunito Sans !important;
    }
    .font-size {
    
      font: normal normal bold 6px/4px Nunito Sans !important;
     
    }
    .help-dial .feature1,
    .help-dial .feature2{
      top: 8%;
      width: 35vw;
    }
    .help-dial .feature3,
    .help-dial .feature5{
      top: 35%;
      width: 30vw;
    }
    .help-dial .feature6,
    .help-dial .feature4{
      top: 68%;
      width: 35vw;
    }
    .help-dial .line.pos2 {
      margin-top: 32.5%;
    }
    .help-dial .line.pos3 {
      margin-top: 52.5%;
    }
  }
  @media (max-width: 340px) {
    .help-dial{
      padding: 35% 0;
    }
    .help-dial .feature1,
    .help-dial .feature2{
      top: 12%;
      width: 35vw;
    }
    .help-dial .feature3,
    .help-dial .feature5{
      top: 37%;
      width: 27vw;
    }
    .help-dial .feature6,
    .help-dial .feature4{
      top: 68%;
      width: 35vw;
    }
    .help-dial .line.pos2 {
      margin-top: 37.5%;
    }
    .help-dial .line.pos3 {
      margin-top: 65.5%;
    }
  }