@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;800&display=swap");


.sectionone{
    /* width: 100vw; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 94px;
    margin-top: 63px;
  }


.myleft{
  width: 570px;
}

.myleft h2{

  font: normal normal 800 95px/90px Nunito Sans;
  letter-spacing: 0px;
  color: #152D79;
  text-transform: capitalize;

}

.myleft p {
  font: normal normal 600 45px/61px Nunito Sans;
letter-spacing: 0px;
color: #152D79;

}


.myleft p span{

font: normal normal 800 45px/61px Nunito Sans;
letter-spacing: 0px;
color: #152D79;
}


.join{
  background: #EE8B3F 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 34px;
padding:14px 54px;
font: normal normal bold 24px/35px Nunito Sans;
letter-spacing: 0px;
color: #FCFCFC;
border:none;
}

.save{

  font: normal normal 800 24px/30px Nunito Sans;
letter-spacing: 0px;
color: #EE8B3F;
}

.yearly{
  font: normal normal bold 24px/30px Nunito Sans;
  letter-spacing: 0px;
  color: #152D79;
}

.savediv{
  display: inline-block;
  background: #7691D624 0% 0% no-repeat padding-box;
border-radius: 5px;
padding:6px 25px 6px 19px;
}

.joindiv{
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: flex-start;
  row-gap: 33px;
  margin-top: 54px;
}

.myright{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.myright img{
  width: 557px;
height: 562px;
}

.myright .highspeed{
  width: 207px;
  height: 111px;
  
  background: #FCFCFC 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #7691D6;
  border-radius: 10px;
  position: absolute;
  top: 66px;
  left: -73px;
  display: flex;
  justify-content: space-evenly;
 
  flex-direction: column;
  align-items: center;
}

.myright .highspeed h4{
  text-align: left;
font: normal normal 800 16px/22px Nunito Sans;
letter-spacing: 0px;
color: #152D79;
opacity: 1;
}

.myright .highspeed img{

  width:48px ;
  height: 48px;
  
}

.highimage{

  display: flex;
  column-gap: 10px;
}

.bestvaluecontainer{
  width: 211px;
  height: 111px;
  background: #FCFCFC 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #7691d6;
  border-radius: 10px;
  position: absolute;
  bottom: 10px;
  right: -49px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bestvaluecontainer h2{
  font: normal normal 800 85px/80px Nunito Sans;
  letter-spacing: 0px;
  color: #152D79;
  position: relative;
  margin-top: 35px;
  

}

.bracket{
  font: normal normal 800 35px/35px Nunito Sans;
letter-spacing: 0px;
color: #152D79;
}

.mo{
  font: normal normal 800 35px/35px Nunito Sans;
letter-spacing: 0px;
color: #152D79;
}

.bestvalue{
  background: #EE8B3F 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 5px;
font: normal normal 800 30px/35px Nunito Sans;
letter-spacing: 0px;
color: #FCFCFC;
width: 184px;
height: 48px;
position: absolute;
top: -31px;
right: 56px;
display: flex;
justify-content: center;
align-items: center;
}

.dollar{
  font: normal normal 800 30px/80px Nunito Sans;
  position: relative;
  bottom:34px;
color: #152D79;
}



.unlimiteddiv{
  height: 165px;
  background: #F5F7FF 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 57px;
}

.unlimiteddiv div{

  display: flex;
  align-items: center;
}


.unlimiteddiv .textdiv{

  font: normal normal 600 24px/30px Nunito Sans;
  letter-spacing: 0px;
  color: #18317B;
  width: 113px;
}


.nationwidediv{
  background-image: url(../assets/Group258.svg);
  height: 336px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 94px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 10px;
}

.nationwideleft{
  width: 436px;
height: 214px;
/* UI Properties */
background: #FCFCFC 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #8DA4DE03;
border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.nationwideright h2{
  font: normal normal 800 45px/65px Nunito Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
}

.nationwideright p{
font: normal normal bold 24px/35px Nunito Sans;
letter-spacing: 0px;
color: #FFFFFF;
}

.nationwideleft h2{
  font: normal normal 800 30px/35px Nunito Sans;
letter-spacing: 0px;
color: #152D79;
margin-top: 29px;
}

.zipcode{
  width: 147px;
height: 52px;
/* UI Properties */
border: 2px solid #7691D6;
border-radius: 37px;
opacity: 0.97;
}

.flex{

  display: flex;
  column-gap: 15px;
  /* background-image: url(../assets/Icon\ material-location-on.svg); */
  background-repeat: no-repeat;
  background-position: 13px 14px;
  background-size: 16px;
}

.flex input{
  position: relative;
  top: 4px;
  left: 42px;
  border-radius: 100px;
  border:none;
  width: 100px;
  font: normal normal bold 18px/40px Nunito Sans;
letter-spacing: 0px;
color: #9D9D9D;

}

.flex input:focus{
  outline:none;
}



.letsgo{
  width: 147px;
height: 52px;
/* UI Properties */
background: #152D79 0% 0% no-repeat padding-box;
border-radius: 37px;
opacity: 0.97;
text-align: center;
font: normal normal 800 20px/40px Nunito Sans;
letter-spacing: 0px;
color: #FCFCFC;
border:none;
}


.howitworksdiv{
  display: flex;
  align-items: center;
  flex-direction: column;

background: #F5F7FF 0% 0% no-repeat padding-box;
padding:63px;
text-align: center;
row-gap: 72px;

}



.howtext h2{
  font: normal normal 800 45px/75px Nunito Sans;
letter-spacing: 0px;
color: #152D79;
text-transform: capitalize;
} 

.howtext p{
  text-align: center;
font: normal normal bold 24px/35px Nunito Sans;
letter-spacing: 0px;
color: #405EBF;

width: 530px;
}

.chooseplan{

  display: flex;
}


.chooseplan .one{
  font: normal normal bold 85px/30px Nunito Sans;
  letter-spacing: 0px;
  color: #405EBF;
  margin-bottom: 25px;
}

.chooseplan .two{
  font: normal normal bold 24px/30px Nunito Sans;
letter-spacing: 0px;
color: #18317B;
}

.chooseplan .three{

  width: 236px;
height: 52px;
text-align: center;
font: normal normal 400 20px/25px Nunito Sans;
letter-spacing: 0px;
color: #152D79;
}

.chooseplan div{

  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.joinbtn{
  width: 312px;
height: 60px;
/* UI Properties */
background: #152D79 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 34px;
font: normal normal bold 20px/35px Nunito Sans;
letter-spacing: 0px;
color: #FCFCFC;
text-transform: capitalize;
border:none;
margin-top:60px;
}

.commadiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:80px 0px;
  row-gap: 37px;
}

.commadiv h2{
  width: 588px;
height: 61px;
/* UI Properties */
text-align: center;
font: normal normal bold 45px/55px Nunito Sans;
letter-spacing: 0px;
color: #152D79;
}

.commadiv .p1{
  width: 835px;
line-height: 22px;
/* UI Properties */
text-align: center;
letter-spacing: 0px;
color: #152D79;
font: normal normal 600 20px/30px Nunito Sans;
}

.commadiv .p2{
  width: 835px;
  line-height: 22px;
  /* UI Properties */
  text-align: center;
  letter-spacing: 0px;
  color: #152D79;
  font: normal normal bold 20px/30px Nunito Sans;
  margin-top: 20px;
}

.comma{
  width: 87px;
height: 63px;
}
.payless{

  font: normal normal bold 45px/55px Nunito Sans;
letter-spacing: 0px;
color: #152D79;
width: 974px;
margin-top: 26px;
}


.mypagination{
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
@media only screen and (max-width: 1300px) {
  
  .cardn{

    flex-direction: column;
  }

  

}

@media only screen and (max-width: 1200px) {

  /* .commadiv .p1{
    width: 100vw;

  } */

  .sectionone{
    flex-direction: column-reverse;
    row-gap: 63px;
  }

  .chooseplan{
    display: flex;
    row-gap: 39px;
    flex-direction: column;
  }

  .chooseplan img{
    transform: rotate(90deg) scale(0.8) translateX(25px);
  }
  
  .howtext{

    transform: scale(0.6);
  }
  
  .payless{
    transform: scale(0.4);

  }

  .chooseplan .text{
    margin-top: 99px;
  }

}
@media (max-width: 1024px) {
  .join {
    background: #EE8B3F 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 34px;
    padding: 9px 41px !important;
    font: normal normal bold 20px/34px Nunito Sans !important;
    letter-spacing: 0px;
    color: #FCFCFC;
    border: none;
}

}
@media only screen and (max-width: 870px){

  .commadiv{
    padding:0px;
    transform:scale(0.5)
  }
  
  }
@media only screen and (max-width:768px){
  .check-coverage-btn{
    transform: scale(0.9)
  }
}
@media only screen and (max-width: 735px) {

  .sectionone{
    transform: scale(0.5);
    height: 600px;
    margin-top: 41px;
  }

  .card1{
    flex-direction: column;
    row-gap: 61px;
  }
  .unlimiteddiv{
    flex-direction: column;
    row-gap: 50px;
    height: fit-content;
    padding:66px;
  }

.nationwidediv{
  
    flex-direction: column;
    row-gap: 50px;
    height: 800px;
    padding:0px 40px;
}

.nationwideleft{
  width: 87vw;
}

.paragrap{

  transform: scale(0.9);
}

.howitworksdiv{

  row-gap: 0px;
  padding: 0px
}

}
@media only screen and (max-width: 480px){

  .commadiv{
    padding: 35px 10px 50px;
    transform: unset;
  }
  .commadiv h2{
    width: unset;
    height: unset;
    font: normal normal bold 26px/40px Nunito Sans;
  }
  .commadiv .p1,
  .commadiv .p2{
    width: unset;
    font: normal normal 600 18px/25px Nunito Sans;
  }
  .payless{
    width: 100%;
    font-size: 18px;
    line-height: 25px;
    transform: unset;
  }
  }
@media only screen and (max-width: 350px){
  .nationwideleft h2{
    font-size: 27px;
  }
}
@media only screen and (max-width: 320px){
  .check-coverage-btn {
    transform: scale(0.8);
}
}
.carousel-indicators{
  bottom: -50px;
}
.carousel-indicators [data-bs-target]{

  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #EE8B3F;
}
.carousel-item{
  min-height: 180px;
}
.carousel-control-next,
.carousel-control-prev{
  display: none;
}