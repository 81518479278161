* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   font-family: sans-serif;
}
body{
  overflow-x: hidden;
  /* padding: 0 !important; */
}
.overlay {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 99999;
   background: radial-gradient(rgba(20, 20, 20, 0.5), rgba(0, 0, 0, 0.5));
 }
 .loaderImage-container {
   position: absolute !important;
   left: 50% !important;
   top: 50% !important;
   transform: translateX(-50%);
 }
 .accordion-button{
  font-size: calc(1.275rem + .3vw);
    font-weight: bold;
    border-bottom: 1px solid #d4dbde!important;
    border-radius: unset !important;
    box-shadow: unset !important;
    color: #152D79 ;
 }
 .accordion-button:not(.collapsed){
  color: #152D79;
  background-color: unset;
 }
 .accordion-body{
  padding: 0;
 }
 /* .device-wrap .cart-btn {
 
  width: 152px;
  height: 35px;
  background: #EE8B3F;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 34px;
  color: white;
  cursor: pointer;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
} */

 .device-wrap .cart-btn {
  position: relative;
  overflow: hidden;
  padding: 10px 20px;
  background-color: #EE8B3F;
    font-family: 'Nunito Sans';
  border-radius: 34px;
  color: white;
  border: none;
  cursor: pointer;
}

.device-wrap .cart-btn:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  opacity: 0;
  pointer-events: none;
}

.device-wrap .cart-btn.loading:after {
  opacity: 1;
}

.device-wrap .cart-btn.loading {
  pointer-events: none;
}



.btnfixed{
  position: fixed;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
}


@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}






.detail-section .cart-btn:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  opacity: 0;
  pointer-events: none;
}

.detail-section .cart-btn.loading:after {
  opacity: 1;
}

.detail-section .cart-btn.loading {
  pointer-events: none;
}


@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
