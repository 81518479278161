p{
    margin: 0px;
}

.fields{
    display: flex;
}
@media screen and (max-width: 768px) {
    .make-payment-dialog{
        flex-direction: column !important;
        gap:20px;
    }
    .make-payment-dialog button{
        width: unset !important;
    }

}