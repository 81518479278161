.navbarnav {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-top: 7px solid #f77908;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0px;
  z-index: 999;
  position: relative;
  }

  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    /* height: 80px !important; */
  }
  
  .containernav {
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 1300px;
    /* margin-right: auto; */
    /* margin-top: 25px; */
    /* margin-left: auto; */
    /* padding-right: 50px; */
    /* padding-left: 50px; */
    align-items: center;
    justify-content: space-between;
}
  
  
  .navbar-logo {
    /* background: transparent url('./assets/logo.png') 0% 0% no-repeat padding-box; */
    opacity: 1;
    /* left: 103px; */
    margin-top: 20px;
    margin-bottom: 20px;
    /* width: 188px;
    height: 67px; */
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
  }
  
  .nav-item {
    text-decoration: none;
    margin-top: 25px;
    
  }
  
  .nav-linknav:hover {
    color: #f77908;
  }
  .active.nav-linknav{
    color: #f77908;
  }
  .nav-linknav {
    text-decoration: none;
    padding: 0.5rem 1rem;
    margin-top: 46px;
    left: 731px;
    width: 52px;
    height: 27px;
    text-align: center;
    font: normal normal bold 16px/28px Nunito Sans;
    letter-spacing: 0px;
    color: #152D79;
    opacity: 1;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
 .btn1{
  padding: 5px 18px;
  margin-top: 18px;
 }
 .btn1 button{
  font-size: 14px;
 }
 .nav-cart{
  /* margin-left: 257px;
  font-size: larger; */
  margin-top: 5px;
  top: 46px;
  left: 1547px;
  width: 40px;
  height: 41px;
  background: transparent url(./assets/Icon\ feather-shopping-cart.png) 0% 0% no-repeat padding-box;
  opacity: 1;
 cursor: pointer;
 }


.cartlist h2{

text-align: left;

margin-left: 27px;

}

.cartlist .cross{
  position: relative;
  left: -175px;
  top: 18px;
  font-size: 17px;
  font-weight: 800;
  background:transparent;
  border:none;
  cursor: pointer;
}

.cartlist .cart-items > div{
  display: flex;
  justify-content: center;
  column-gap: 80px;
  /* margin: 27px; */
  margin: 20px 30px;
  /* padding: 0 20px; */
}
.close-btn-cart{
  position: absolute;
  right: 20px;
}
.items-item-1 {
  font-weight: bold;
}

.myflex{

  display: flex;
  justify-content: space-between;
  margin: 20px 30px;
  align-items: center;
  /* padding: 0 20px; */
}


.signinput{
  width: 85%;
  height: 40px;
  height: 50px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans",sans-serif;
  padding: 0 20px;
  font-size: 18px;
  margin:15px 0px;
}

.popup h5{
  font-size: 21px;
  margin-bottom: 18px;
  
  margin-top: 20px;
}

.popup button{
  background: #EE8B3F;
    border-radius: 50px;
    border: none;
    height: 50px;
    padding: 0 40px;
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    font-family: "Open Sans",sans-serif;
    font-weight: 700;
    outline: none;
    cursor: pointer;
    margin:15px 0px
}

.itemscart{

  max-height: 485px;
  max-width: 370px;
  font-size: 14px;
  width: 420px;
}

.checkout{
  width: 125px;
    height: 41px;
    background: #152D79 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #769af5;
    border-radius: 36px;
    opacity: 0.97;
    color: white;
    padding: 7px 21px;
    font-size: 17px;
    display: block;
    margin:50px auto 80px;
    position: relative;
    bottom: 30px;
}
.itemscart .checkout{
  margin:50px auto 4px;

}
@media screen and (min-width: 1550px) {
  .containernav {
    max-width: 1500px;
  }
}
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
    .addtocard-icon{
      margin: 0;
    }
    .nav-cart{
      display: none;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      /* height: 90vh; */
      position: absolute;
      top: 80px;
      /* left: -100%; */
      right: -100%;
      opacity: 1;
      justify-content: flex-start;
      transition: all 0.5s ease;
      padding-left: 0;
      width: 250px;
      height: 100vh;
    }
  
    .nav-menu.active {
      background: #fff;
      /* left: 0; */
      right: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
      display: block;
    }
  
    .nav-linknav{
      text-align: left;
      padding: 10px 20px;
      width: 100%;
      display: table;
      margin-top: 0;
    }
  
    .nav-linksnav:hover {
      color: #f00946;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
      margin-top: 0;
    }
 
  
    .navbar-logo {
      position: absolute;
    top: -35px;
    left: -31px;
    width: 193px;
    height: 66px;
    transform: translate(25%, 50%);
    margin-top: 25px;
    } 
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }
    
  }


  .myaccount-btn{
    height: 41px;
    background: #152D79 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #769af5;
    border-radius: 36px;
    opacity: 0.97;
    color: white;
    font-size: 17px;
}
.myaccount-btn:hover{
  background-color: #f77908;
  color: #fff;
  border: none;
}

.cart-items .remove-item-action {
  max-height:0;
  transition:  0.4s linear;
  overflow:hidden;
  color:red;
  cursor:pointer;
  margin: 0px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0px !important;
}
.cart-items:hover  .remove-item-action {
  max-height:45px;
  margin: 0;
  padding: 10px 0px !important;

}
@media screen and (max-width: 1900px) {

.section4why .left{
  left: 5%;
}
.section4why .right{
  right: 5%;
}
}
  

  @media screen and (max-width: 960px) {
    
    .mbl-btn{
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      /* gap: 20px; */
      margin-top: 0px;
      padding: 0;
    }
    .mbl-btn button{
      all: unset;
      font: normal normal bold 16px/28px Nunito Sans;
      letter-spacing: 0px;
      color: #152D79 !important;
      width: 84%;
      text-align: left;
      padding: 10px 20px;
      background-color: unset !important;
    }
    .side-nav-cart{
      display: flex !important;
      font: normal normal bold 16px/28px Nunito Sans;
    letter-spacing: 0px;
    color: #152D79;
    cursor: pointer;
    }
    .side-nav-cart span{
      font: normal normal bold 16px/28px Nunito Sans;
      letter-spacing: 0px;
    }
    .cart-parent{
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 10px 20px;
    }
    .itemscart{
      width: 90vw;
    }
    .nav-menu{
      display: none;
    }
    .mobile-nav-overly{
      width: 100%;
        height: 100%;
        z-index: 2;
        top: 0;
        left: 0;
        position: fixed;
        background: rgba(39, 37, 34, 0.6);
        overflow: hidden;
    }
    .side-menu-header{
      display: block !important;
    }
    .side-menu-header > div{
      margin-bottom: 15px;
      padding-bottom: 5px;
      border-bottom: 1px solid #152D79;
    }
    .nav-menu.active {

      position: fixed;
      top: 0;
      z-index: 2;
    }
    .containernav,.navbarnav{
      z-index: unset;
    }
    .cart-items  .remove-item-action {
      max-height:45px;
      margin: 0;
      padding: 10px 0px !important;
    
    }
    .navbar-container {
      
      height: 80px !important;
  }
  }
