.part2 {
  background-image: url(../assets/Group258.svg);
  display: flex;
  background-repeat: no-repeat;
  justify-content: center;
  height: 453px;
  opacity: 1;
  background-size: cover;
  align-items: center;
  opacity: 1;
}
.text-belowbtn{
  display: flex;
    height: 232px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.belowbtn1{
  text-align: center;
    font: normal normal 600 20px/30px Nunito Sans;
    letter-spacing: 0px;
    color: #152D79;
    width: 820px;
   
}
.buttonw1{
  font-family: "Nunito Sans";
  width: 247px !important;
    height: 60px !important;
  margin-top: 41px !important;
  margin-right: 0px !important;
  margin-bottom: 54px !important;
  background: 0% 0% no-repeat padding-box padding-box rgb(21, 45, 121);
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  border-radius: 34px !important;
  opacity: 1;
  border: none !important;
  color: rgb(255, 255, 255);
  font: normal normal bold 16px/40px Nunito Sans;
}
.border{
  width: 82%;
  margin-top: 35px;
  height: 10px;
  border: unset !important;
  border-bottom: 1px solid #86A1F8 !important;
}
/* .plans-main .border{
  border-bottom: unset !important;
  border-right: unset !important;
  border-left: unset !important;

} */
.part2-card {
  background-color: #f5f7ff;
  width: 379px;
  margin-left: 64px;
  border-radius: 14px;
  padding: 22px;
}


.rt1{
  display: grid;
  align-items: center;
  margin-right: 25px;
}

.pt1{
  margin-bottom: 24px;
  text-align: center;
  font: normal normal 800 30px/99px Nunito Sans;
  letter-spacing: 0px;
  color: #152D79;
  opacity: 1;

 
}
.bbt{
  background-image: url(../assets/Icon\ material-location-on.svg);
  background-repeat: no-repeat;
  background-position: 13px 14px;
  background-size: 16px;
}

.btnbasic{
  background: #405EBF 0% 0% no-repeat padding-box;
border-radius: 5px;
padding: 8px 17px;
height: 49px;
border:none;
font-size: 24px;
color:#FFFFFF;
font: normal normal 800 22px/35px Nunito Sans;
position: absolute;
top: -24px;
left: 41px;
text-transform: uppercase;
display: flex;
justify-content: center;
align-items: center;
}

.plandesc ul li{

  display: flex;
  justify-content: center;
  margin-top: 24px;
  width: 346px;
  margin-left: 5%;
  /* text-align: left; */
  font: normal normal bold 24px/40px Nunito Sans;
  letter-spacing: 0px;
  color: #152D79;
  text-transform: capitalize;
  opacity: 1;
}

.btnbasic1{
  background: #405EBF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width:317px;
  height: 49px;
  border:none;
  font-size: 24px;
  color:#FFFFFF;
  font: normal normal 800 24px/35px Nunito Sans;
  position: absolute;
  top: -24px;
  left: 41px;
}


.rt1h{
  font-size: 45px;
    font-family: Nunito Sans, ExtraBold;
    text-align: left;
    font: normal normal 800 43px/72px Nunito Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
   
}
.rt1p{
  text-align: left;
    font: normal normal bold 27px/39px Nunito Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.cardd1{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  width: 100%;

}
.k1{
  width: 537px;
    height: 32px;
    text-align: center;
    font: normal normal bold 24px/40px Nunito Sans;
    letter-spacing: 0px;
    color: #405EBF;
    opacity: 1;
}

.kk1{
  
  margin: 79px 0px;

}

.kk2{
  width: 562px;
  height: 61px;
  text-align: center;
  font: normal normal 800 45px/75px Nunito Sans;
  letter-spacing: 0px;
  color: #152D79;
  opacity: 1;
}
.month1{
  font-size: 29px;
}
.parice-card{
  width: 421px;
  min-height: 399px;
  display: flex;
  margin-right: 14px;
  flex-direction: column;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  background: #F5F7FF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: 5px solid #fff;
}
.parice-card:hover > .btnbasic{


  background-color:#EE8B3F;
 
}
.parice-card:hover {
  border: 5px solid #EE8B3F
}
.parice-card:hover > .btnbasic1{

  border: 5px solid #EE8B3F;
  background-color:#EE8B3F;
 
}

.cardd2{
  display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 600px; */
    /* width: 100%; */
    /* flex-direction: column; */
}

.talk{
  display: flex;
  /* justify-content: center; */
  margin-top: 24px;
  width: 82%;

}
.l1{
  width: 346px;
  margin-left: 5%;
  /* text-align: left; */
  font: normal normal bold 24px/40px Nunito Sans;
  letter-spacing: 0px;
  color: #152D79;
  text-transform: capitalize;
  opacity: 1;
}
.tick1{
  width: 22px;
    height: 38px;
    opacity: 1;
}
.doll2{
  height: 42px;
  margin-top:56px;
  font: normal normal 800 29px/58px Nunito Sans;

  color: #152D79;
  flex-direction: row;
  display: flex;


  align-items: end;

  font: normal normal 800 30px/93px Nunito Sans;
  letter-spacing: 0px;
  color: #152D79;
  opacity: 1;


}

@media only screen and (max-width: 735px){

  .cardd1 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 604px;
    flex-direction: column;
    width: 100%;
  }
  .kk2{
    text-align: center;
    font: normal normal 800 23px/53px Nunito Sans !important;
    letter-spacing: 0px;
    color: #152D79;
    display: flex;
    opacity: 1;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }
  .btnbasic{
    position: initial !important;
  }
}







@media only screen and (max-width: 1450px) { 

  .pt1 {
    margin-bottom: -2px !important;
    text-align: center;
    font: normal normal 800 30px/99px Nunito Sans !important;
    letter-spacing: 0px;
    color: #152D79;
    opacity: 1;
}
.paragrap {
  display: flex;
  justify-content: start;
  width: 100%;
  /* justify-content: center; */
  height: 625px !important;
  flex-direction: column;
  align-items: center;
}
.covrage-section {
  height: 128px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 30px;
  background: #F5F7FF 0% 0% no-repeat padding-box;
  opacity: 1;
  align-items: center;
  height: 122px !important;
}
.icon1{
  width: 46px;
}
.bbt{
  border: 2px solid rgb(118, 145, 214);
  border-radius: 37px !important;
  opacity: 0.97;
  color: rgb(157, 157, 157);
  width: 136px !important;
  font-size: 16px !important;
  font-family: "Nunito Sans", Bold;
  height: 51px !important;
  text-align: center;
}
.bbbt{
  border: 2px solid rgb(118, 145, 214);
  border-radius: 37px !important;
  opacity: 0.97;
  color: rgb(157, 157, 157);
  width: 136px !important;
  font-size: 16px !important;
  font-family: "Nunito Sans", Bold;
  height: 51px !important;
  text-align: center;
  border: none;
}
.part2 {
  display: flex;
  background-repeat: no-repeat;
  justify-content: center;
  height: 363px !important;
  opacity: 1;
  background-size: cover;
  align-items: center;
  opacity: 1;
}
.talk-text {
  display: inline-block;
  font-family: Nunito Sans, SemiBold;
  margin-left: 15px !important;
  text-align: left;
  font: normal normal 400 18px/24px Nunito Sans !important;
  letter-spacing: 0px;
  color: #18317B;
  opacity: 1;
}
.parice-card {
  width: 361px !important;
  /* height: 557px !important; */
  display: flex;
  margin-right: 14px;
  flex-direction: column;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  background: #F5F7FF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: 5px solid #fff;
}
.btnbasic {
  background: #405EBF 0% 0% no-repeat padding-box;
  border-radius: 5px !important;
  /* width: 147px !important; */
  height: 41px !important;
  border: none;
  font-size: 24px;
  color: #FFFFFF;
  font: normal normal 800 18px/35px Nunito Sans !important;
  position: absolute;
  top: -21px !important;
  left: 41px !important;
}
.l1 {
  width: 306px !important;
  margin-left: 5% !important;
  /* text-align: left; */
  font: normal normal bold 20px/40px Nunito Sans !important;
  letter-spacing: 0px;
  color: #152D79;
  text-transform: capitalize;
  opacity: 1;
}
.buttonw1 {
  font-family: "Nunito Sans";
  width: 231px !important;
  height: 51px !important;
  margin-top: 31px !important;
  margin-right: 0px !important;
  /* margin-bottom: 19px !important; */
  background: 0% 0% no-repeat padding-box padding-box rgb(21, 45, 121);
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  border-radius: 34px !important;
  opacity: 1;
  border: none !important;
  color: rgb(255, 255, 255);
}
.btnbasic1 {
  background: #405EBF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 270px !important;
  height: 42px !important;
  border: none;
  font-size: 24px !important;
  color: #FFFFFF;
  font: normal normal 800 19px/35px Nunito Sans !important;
  position: absolute;
  top: -20px !important;
  left: 41px !important;
}
.belowbtn1 {
  text-align: center;
  font: normal normal 600 16px/30px Nunito Sans !important;
  letter-spacing: 0px;
  color: #152D79;
  width: 684px !important;

}
.text-belowbtn {
  display: flex;
  height: 197px !important;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.mo15{
  font: bold 57px/10px "Nunito Sans" !important;
}
.d0l-1{
  margin-left: -95px !important;
  font: 800 30px / 64px "Nunito Sans" !important;
}


}
@media only screen and (max-width: 480px) {
  .belowbtn1 {
    display: none !important;
  }
  .text-belowbtn{
    display: none !important;
  }
.bbt{
  border: 2px solid rgb(118, 145, 214);
  border-radius: 37px;
  opacity: 0.97;
  color: rgb(157, 157, 157);
  width: 124px !important;
  font-size: 15px !important;
  font-family: "Nunito Sans", Bold;
  height: 51px !important;
  text-align: center;
  margin-bottom: 10px !important;
  margin-left: 6px !important;
}
.icons1, .icons2, .icons3 {
  padding: 15px 6px;
  display: flex;
  align-items: center;
}

.bbbt{
  background: 0% 0% no-repeat padding-box padding-box rgb(21, 45, 121);
  border-radius: 37px !important;
  opacity: 0.97;
  border: none;
  width: 125px !important;
  color: rgb(252, 252, 252);
  height: 50px !important;
  margin-left: 8px !important;
  font-size: 17px !important;
  font-weight: 500 !important;
  font-family: "Nunito Sans", ExtraBold;
  text-align: center;
}



.k1{
  text-align: center;
  font: normal normal bold 18px/17px Nunito Sans;
  letter-spacing: 0px;
  color: #405EBF;
  display: flex;
  text-transform: lowercase;
  opacity: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.kk1 {
  /* height: 181px; */
  /* padding: 27px; */
  width: 100%;
  height: 128px !important;
  margin: 39px 0px;
}
.l1 {
  width: 263px !important;
  margin-left: 8%;
  /* text-align: left; */
  font: normal normal bold 24px/40px Nunito Sans;
  letter-spacing: 0px;
  color: #152D79;
  text-transform: capitalize;
  opacity: 1;
}
.cardd1 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 604px;
  flex-direction: column;
  width: 100%;
}

/* .buttonw1{
  font-family: "Nunito Sans";
  width: 284px;
  height: 67px;
  margin-top: 41px;
  margin-right: 0px;
  margin-bottom: 54px;
  background: 0% 0% no-repeat padding-box padding-box rgb(21, 45, 121);
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  border-radius: 34px;
  opacity: 1;
  color: rgb(255, 255, 255);
} */
.parice-card{
  margin-bottom: 55px;
  width: 322px !important;
  min-height: 399px;
  display: flex;
  margin-right: 0px;
  flex-direction: column;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  background: #F5F7FF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: 5px solid #fff;
}
.mo-15{
  font: bold 40px / 3px "Nunito Sans" !important;
}
.btnbasic{
  font :normal normal 800 13px/35px Nunito Sans !important
}
.d0l-1{
  margin-left: -131px !important;
  font: 800 26px / 13px "Nunito Sans" !important;
}
.btnbasic1 {
  background: #405EBF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 262px;
  height: 42px;
  border: none;
  font-size: 24px;
  color: #FFFFFF;
  font: normal normal 800 17px/32px Nunito Sans;
  position: absolute;
  top: -19px;
  left: 25px !important;

}
.btnbasic {
  background: #405EBF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  /* width: 155px; */
  height: 41px;
  border: none;
  font-size: 24px;
  color: #FFFFFF;
  font: normal normal 800 17px/35px Nunito Sans;
  position: absolute;
  top: -17px;
  left: 41px !important;
}
.citem1, .citem2 {
  height: 424px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #cdd8f3;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  padding: 14px !important;
  margin: 11px 4px !important;
  width: 90% !important;
}
.parice-card .doll2{
  margin-top: 0;
}
.doll2 .d0l-1{
  margin-left: 0 !important;
}
}