.content-title {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 34.4px;
    line-height: 60px;
    text-align: center;
    color: #152D79;
    margin-top: 20px;
    margin-bottom: 10px;
}

.device-wrap {
    margin-left: 0;
    margin-right: 0;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: center;
    column-gap: 5%;
}

.device-wrap .item {
    width: 100% !important;
    max-width: 30%;
    /* border: 1px solid #dbddde; */
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;
    /* width: calc((100% / 3) - 30px);
    border-radius: 15px; */
    padding: 0;
    float: none;
    border: 4px solid #152D79;
    filter: drop-shadow(3px 3px 24px rgba(0, 0, 0, 0.15));
    border-radius: 10px;
}

.device-wrap .item:hover {
    border: 4px solid #EE8B3F;
}

.device-wrap .img-wrap {
    /* width: 380px; */
    height: 341px;
    box-shadow: inset 0px -4px 34px -1px rgba(0, 0, 0, 0.26);
    border-radius: 10px 10px 0px 0px;
}

.device-wrap .img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.device-wrap .device-name {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.05em;
    color: #152D79;
}

.device-wrap .device-specs {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.05em;
    color: #152D79;
}

.device-wrap ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.device-wrap ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

.device-specs p {
    display: none;
}

.device-wrap ul li:first-child {
    display: block;
}

.device-wrap ul li:not(:first-child) {
    display: none;
}

.plan-price {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: rgba(60, 60, 60, 0.5);
}

.price2 {
    font-size: 12.1506px;
    line-height: 17px;
}

.plan-price span {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #152D79;
    letter-spacing: 0.05em;
}

.plan-price .price2 span {

    font-size: 18.2259px;
    line-height: 25px;
}



.closeModalBtn {
    display: block !important;
    padding: 0px 15px;
    cursor: pointer;
}

.closeModalBtn span {
    float: right;
    font-size: 40px;
    font-weight: 300;
    color: #000000;
    position: absolute;
    right: 15px;
}

/* .modal-section {
    height: 50%;
} */

.upper-modal {
    padding: 60px 50px 50px 50px;
    display: flex;
}

.upper-modal .img-wraper img {
    box-shadow: inset 0px -4px 34px -1px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    width: 100%;
}

.upper-modal .img-wraper {
    /* width: 500px; */
    width: 205px;
}

.upper-modal .detail-section {

    padding-left: 50px;
}

.device-title {
    font-family: 'Nunito Sans';
    color: #152D79;
    font-weight: 700;
}

.upper-modal .tick-icon {
    width: 12px;
    height: 9px;
}

.upper-modal .list-item {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
}

.upper-modal .price {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #3C3C3C;
}

.upper-modal .price span {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #152D79;
}

.upper-modal .add-cart-btn {
    border-bottom: 1px solid rgba(21, 45, 121, 0.2);
    border-top: 1px solid rgba(21, 45, 121, 0.2);
}

.upper-modal .add-cart-btn .cart-btn {
    all: unset;
   min-width: 152px;
    height: 35px;
    background: #152D79;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 34px;
    color: white;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding:0px 10px;
    position: relative;
}

.lower-modal {
    padding: 0 50px 25px 50px;
}

.lower-modal .specification-wrap {
    background: rgba(21, 45, 121, 0.1);
    border-radius: 12px;
    padding: 25px 30px 16px 30px;
}

.lower-modal .spec-title {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.2px;
    color: #152D79;

}
.specification-wrap .item{
    padding: 5px;
}
.specification-wrap .item:nth-child(even){
    background: #ffff;
}
.specification-wrap .item .spec{
font-family: 'Nunito Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
/* line-height: 19px; */
letter-spacing: 0.2px;
color: #152D79;
}
.specification-wrap .item .spec-detail{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #555863;
}

@media screen and (max-width: 960px) {
    .upper-modal.modal-section {
        flex-direction: column;
        row-gap: 40px;
    }

    .upper-modal .img-section{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .upper-modal .detail-section{
        padding-left:0px ;
    }
}
@media screen and (max-width: 767px) {
    .device-wrap {

        justify-content: center;

    }

    .device-wrap .item {
        max-width: 500px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .content-title {
        text-align: center;
        font: normal normal 800 27px/32px Nunito Sans;
        letter-spacing: 0px;

    }
}