.account-main {
  opacity: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 83px;
  height: 157px;
}
.css-15v22id-MuiAccordionDetails-root {
  padding: 8px 17px 4px !important;
  margin-top: -16px !important;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  display: flex !important;
  align-items: flex-start !important;
  margin-top: -10px !important;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 9px 0 !important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px !important;
}
.accout-heading {
  text-align: center;
  letter-spacing: NaNpx;
  color: #152d79;
  text-transform: capitalize;
  opacity: 1;
  position: relative;
}

.act-h {
  text-align: center;
  font: normal normal 800 55px/63px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  text-transform: capitalize;
  background-color: #fcfcfc;
  position: relative;
}
.Group26 {
  position: absolute;
  /* top: 213px; */
  top: 60px;
  height: 6.5px;
  display: flex;
}

.acount-form {
  height: 618px;
  background: #546cb6 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  display: flex;
  margin-top: 58px;
  justify-content: center;
  align-items: center;
  opacity: 1;
}
.form-heading {
  display: grid;
  align-items: center;
  margin-right: 78px;
  margin-bottom: 83px;
}
.support-link {
  font: normal normal 31px/93px Nunito Sans;
    letter-spacing: 0px;
    text-align: center;
    color: #ffffff;
    opacity: 1;
    text-decoration: underline;
}

.frm-h {
  font: normal normal 800 50px/72px Nunito Sans;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}

.form-card {
  width: 538px;
  height: 447px;
  background: #fcfdff 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
}
.input-div {
  padding: 3px;
}

.input1 {
  width: 428px;
  height: 56px;
    margin-left: 0px;
    padding: 11px;
  margin-top: 56px;
  background: #f5f7ff 0% 0% no-repeat padding-box;
  border: 2px solid #a6bcf533;
  border-radius: 5px;
}

.input2 {
  width: 428px;
  height: 56px;
  margin-left: 0px;
    padding: 11px;
    margin-top: 15px;
  background: #f5f7ff 0% 0% no-repeat padding-box;
  border: 2px solid #7195ed33;
  border-radius: 5px;
  opacity: 0.97;
  padding: 16px;
}
.input3 {
  width: 428px;
  height: 138px;
  margin-top: 15px;
  margin-left: 0px;
  padding: 11px;
  background: #f5f7ff 0% 0% no-repeat padding-box;
  border: 2px solid #a6bcf533;
  border-radius: 5px;
  opacity: 0.97;
  resize: none;
  padding: 16px;
}

.submit-btn {
  width: 147px;
  border: none;
  height: 52px;
  background: #ee8b3f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #debea6;
  border-radius: 37px;
  opacity: 0.97;
  margin-top: 20px;
}

.submit-btntext {
  text-align: center;
  font: normal normal 800 21px/32px Nunito Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}
.accoutn-part2{
    opacity: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background: #F5F7FF;
    height: 515px;
}

.img289-div {
    display: flex;
    align-items: center;
}

.img-text{
text-align: left;
letter-spacing: NaNpx;
color: #152D79;
text-transform: capitalize;
opacity: 1;
margin-left: 38px;
font: normal normal 800 45px/61px Nunito Sans;
}

.img-num{
    text-align: center;
    font: normal normal bold 30px/30px Nunito Sans;
    letter-spacing: 0px;
    color: #405EBF;
    text-transform: capitalize;
    opacity: 1;
}

.acordion-div{
  opacity: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 646px;
  background: #F5F7FF;
  overflow: auto;
  padding-bottom: 123px;
  padding: 20px;
}
.acordion{
    width: 965px;
/* height: 75px; */
border-radius: 5px;
opacity: 1;
}
.acd-text{
  width: 100%;
    text-align: center;
    letter-spacing: NaNpx;
    color: #152D79;
    font-size: 42px;
    
    opacity: 1;
    margin-top: 68px;
    margin-bottom: 49px;
    line-height: 58px;
}

.acd-head{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F7FF;
}
.acordion-para{
    text-align: left;
    font: normal normal bold 19px/29px Nunito Sans;
    letter-spacing: 0px;
    color: #152D79;
    opacity: 1;

}
.from{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.acordion-p{
    text-align: left;
    font: normal normal 500 19px/30px Nunito Sans;
    letter-spacing: 0px;
    color: #152D79;
    opacity: 1;
}


@media only screen and (max-width: 480px) {
  .form-heading {
    display: grid;
    align-items: center;
    margin-right: 56px ;
    margin-bottom: 0px;
}
.from {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.account-main {
  opacity: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  height: 45px;
}
    .act-h {
        text-align: center;
        font: normal normal 800 19px/25px Nunito Sans !important;
        letter-spacing: 0px;
        color: #152d79;
        text-transform: capitalize;
        background-color: #fcfcfc;
        position: relative;
    }
    .acordion-div {
      opacity: 1;
      width: 100% !important;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      background: #F5F7FF;
      overflow: auto !important;
    }
    .Group26 {
        display: none;
    }
    .frm-h {
        font: normal normal 800 22px/25px Nunito Sans !important;
        text-align: center;
        color: #ffffff;
        text-transform: capitalize;
        opacity: 1;
        margin-top: 34px;
        
    }
    .acount-form {
      min-height: 632px !important;
        background: #546cb6 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        display: flex;
        margin-top: 36px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        opacity: 1;
    }
    .support-link {
        font: normal normal bold 20px/75px Nunito Sans;
        letter-spacing: 0px;
        text-align: center;
        color: #ffffff;
        text-transform: lowercase;
        opacity: 1;
     
    }
    .form-card {
        width: 334px !important;
        height: 399px !important;
        background: #fcfdff 0% 0% no-repeat padding-box;
        border-radius: 9px;
        opacity: 1;
        transform: none !important;
    }
    .input1,.input2{
      width: 284px !important;
      height: 56px !important;
      margin-left: 0px !important;
      padding: 4px !important;
      margin-top: 0px;
      background: #f5f7ff 0% 0% no-repeat padding-box;
      border: 2px solid #a6bcf533;
      border-radius: 5px;
  }
    
    .input3 {
      width: 284px !important;
      height: 111px !important;
      margin-left: 0px !important;
      padding: 4px !important;
      margin-top: 0px;
      background: #f5f7ff 0% 0% no-repeat padding-box;
      border: 2px solid #a6bcf533;
      border-radius: 5px;
    }
    .submit-btn {
      
      background: #ee8b3f 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #debea6;
      border-radius: 37px;
      opacity: 0.97;
    }
    .Group289{
      margin-top: 91px;
    }
    .img289-div {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .img-text {
        text-align: left;
        letter-spacing: NaNpx;
        color: #152D79;
        text-transform: capitalize;
        opacity: 1;
        margin-top: 49px;
        /* margin-left: 30px; */
        text-align: center;
        font: normal normal 800 18px/31px Nunito Sans;
    }
    .acd-text {
      width: 100%;
      text-align: center;
      letter-spacing: NaNpx;
      color: #152D79;
      font-size: 23px;
      opacity: 1;
      margin-top: 68px;
      margin-bottom: 49px;
    }
    .acordion {
        width: 361px !important;
        border-radius: 5px;
        opacity: 1;
    }
 }

 @media only screen and (max-width: 1250px) {
 
  .Group26 {
  position: absolute;
  top: 40px !important;
  display: flex;
}
.account-main {
  opacity: 1;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 83px !important;
  /* height: 157px; */
  transform: scale(0.8) !important;
}
.form-heading {
  display: grid;
  align-items: center;
  margin-right: 0px;
  margin-bottom: 0px;
  transform: scale(0.8) !important;
}
.form-card {
  width: 566px;
  height: 439px;
  background: #fcfdff 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  transform: scale(0.8);
}
.acount-form {
  height: 470px ;
  background: #546cb6 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  display: flex;
  margin-top: 36px;
  justify-content: center;
  align-items: center;
  opacity: 1;
}
.from{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input1{
  width: 428px;
  height: 56px;
  margin-left: 0px;
  padding: 16px;
  margin-top: 34px;
  background: #f5f7ff 0% 0% no-repeat padding-box;
  border: 2px solid #a6bcf533;
  border-radius: 5px;
}
.input2 {
  width: 428px;
  height: 56px;
  margin-left: 0px;
  margin-top: 20px;
  background: #f5f7ff 0% 0% no-repeat padding-box;
  border: 2px solid #7195ed33;
  border-radius: 5px;
  opacity: 0.97;
  padding: 16px;
}
.input3 {
  width: 428px;
  height: 138px;
  margin-left: 0px;
  margin-top: 20px;
  background: #f5f7ff 0% 0% no-repeat padding-box;
  border: 2px solid #a6bcf533;
  border-radius: 5px;
  opacity: 0.97;
  padding: 16px;
}
}
 

@media (max-width: 930px) {

  .Group26 {
    display: none;
  }
  .acount-form {
    display: flex;
    flex-direction: column;
  }
  .acount-form {
    min-height: 721px ;
    background: #546cb6 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 100%;
    display: flex;
    margin-top: 36px;
    justify-content: center;
    align-items: center;
    opacity: 1;
}
}

@media (max-width: 811px) {
  .act-h {
    text-align: center;
    font: normal normal 800 42px/48px Nunito Sans;
    letter-spacing: 0px;
    color: #152d79;
    text-transform: capitalize;
    background-color: #fff;
    position: relative;
}
.accoutn-part2{
  display: flex;
  flex-direction: column;
}
.img-text {
  text-align: left;
  letter-spacing: NaNpx;
  color: #152D79;
  text-transform: capitalize;
  opacity: 1;
  margin-left: 38px;
  font: normal normal 800 33px/39px Nunito Sans;
}
.Group289{
  width: 297px;
  padding: 10px;
  padding-left: 19px;
}
}
