.slpart {
  background-color: #F5F7FF;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.slpart1 {
  align-items: center;
  background-color: #546cb6;
  width: 100%;
  display: flex;
  justify-content: center;
    padding:70px 0px;
  column-gap: 100px;
  padding-right: 116px;
}

.talk-text1,
.talk-text2,
.talk-text3 {
  font-size: large;
  color: #f7f9ff;
  display: inline-block;
  margin-bottom: 3px;
  padding-bottom: 10px;
}
.talk-text1,
.talk-text2 {
  border-bottom: 1px solid #3793aa;
  /* text-align: left; */
  font: normal normal bold 24px/38px Nunito Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  text-transform: capitalize;
  opacity: 1;
}
.talk-text3 {
  font: normal normal bold 22px/39px Nunito Sans;
}

.s1 {
  display: flex;
  background-repeat: no-repeat;
  justify-content: flex-end;
}




.p1 {
  display: flex;
  justify-content: center;
}
.p11 {
  flex-direction: column;
  display: flex;
}

.i1,
.i2,
.i3 {
  padding: 0px 11px;
  border-right: 7px solid #f5f7ff;
  margin-top: 13px;
}

.i11,
.i22,
.i33 {
  margin-top: -23px;
  padding: 21px 8px;
}
.icon11 {
  color: #fff;
  margin-right: 24px;
  margin-top: 1%;
}
.hhh3 {
  width: 514px;
  height: 61px;
  /* text-align: left; */
  font: normal normal 800 45px/61px Nunito Sans;
  letter-spacing: 0px;
  text-align: center;
  color: #152d79;
  opacity: 1;
}
.he1 {
  margin-right: 135px;
  width: 587px;
  height: 116px;
  opacity: 1;
  display: flex;
  font: normal normal bold 41px/53px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  text-transform: capitalize;
}
.savetalk{
  width: 250px;
  width: 105px;
  height: 7px;
  display: flex;
  width: 39%;
  height: -11px;
  left: 1040px;
  margin-top: -16px;
  margin-left: 218px;
}
.g2b{
  width: 105px;
  height: 5px;
  display: flex;
  width: 19%;
  height: -11px;
  left: 1040px;
  margin-top: -10px;
  margin-left: 18px;
}


.highspeed2{
  background: #F5F7FF 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 100px;
  padding-top: 104px;
}

.highspeed3{
  background: #F5F7FF 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 100px;
  padding-top: 104px;
  padding-bottom: 98px;
}

.unlimiteddiv2{
  display: flex;
  flex-direction: column;
  row-gap: 71px;
  text-align: center;
  align-items: center;
}

.unlimiteddiv2 img{

  width: 246px;
    height: 5.33px;
    position: relative;
    bottom: 17px;
    left: 100px;

}


.unlimiteddiv2 h2{
  text-align: center;
  font: normal normal bold 45px/55px Nunito Sans;
  letter-spacing: 0px;
  color: #152D79;
  text-transform: capitalize;
 
}

.unlimiteddiv2 span{
  font: normal normal 800 45px/55px Nunito Sans;
}

.unlimiteddiv2 button{

  background: #152D79 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 34px;
width: 250px;
height: 60px;
border: none;
font: normal normal bold 20px/35px Nunito Sans;
letter-spacing: 0px;
color: #FCFCFC;
text-transform: capitalize;
padding:11px 55px;
}

.upperdiv{

  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
}

.upperdiv h1{

/* UI Properties */
text-align: left;
font: normal normal 800 45px/61px Nunito Sans;
letter-spacing: 0px;
color: #152D79;
}

.imidiv{
  display: flex;
  column-gap:15px;
}

.imidiv h3{
  font: normal normal bold 24px/30px Nunito Sans;
letter-spacing: 0px;
color: #152D79;
text-transform: capitalize;
}

.smallimi{
  position: relative;
  bottom: 14px;
  right: 5px;
}
.imidiv img{
  margin-right: 16px;
}

.imidiv div{
  display: flex;
  justify-content: center;
  align-items: center;

}




.highspeed2 .line{
  border-right: 1px solid #7691D6;
}






@media only screen and (max-width: 1450px) {

.he1 {
  
    text-align: center;
    opacity: 1;
    display: flex;
    font: normal normal bold 36px/38px Nunito Sans !important;
    letter-spacing: 0px;
    color: #152d79;
    text-transform: capitalize;   
    margin-right: 31px !important;
    width: 425px !important; 
    margin-left: 238px !important;

}

.savetalk {
  height: 6px !important;
    display: flex;
    width: 26% !important;
    margin-top: -43px !important;
    margin-left: 436px !important;
}
.btn-savetalk{
  width: 228px !important;
  height: 52px !important;
  background: 0% 0% no-repeat padding-box padding-box rgb(21, 45, 121);
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  border-radius: 34px !important;
  opacity: 1;
  font-size: 15px !important;
  margin-left: 47% !important;
}
.slpart1 {
  align-items: center;
  background-color: #546cb6;
  width: 100% !important;
  display: flex;
  justify-content: center;
  padding-right: 116px;
  
}
.slpart{
  height: 355px !important;

}
.img259{
  width: 334px !important;
 
}
.talk-text1, .talk-text2 {
  border-bottom: 1px solid #3793aa;
  /* text-align: left; */
  font: normal normal bold 18px/27px Nunito Sans !important;
  letter-spacing: 0px;
  color: #fcfcfc;
  text-transform: capitalize;
  opacity: 1;
}
.talk-text3 {
  font: normal normal bold 18px/27px Nunito Sans !important;
}
.icon11 {
  color: #fff;
  margin-right: 23px;
  /* margin-top: 0%; */
  width: 34px !important;
  height: 46px !important;
}
/* .section2 {
  margin-left: 0% !important;
  margin-top: 54px !important;
  margin-right: 64px !important;
} */
.img260{
  width: 267px !important;
}
.img261{
  width: 267px !important;
  margin-right: 210px !important;
}
.g2b {
  height: 3px;
  display: flex;
  width: 13% !important;
  left: 1040px !important;
  margin-top: -42px !important;
  margin-left: 87px !important;
  margin-bottom: 23px !important;
}
.hhh3 {
  width: 513px !important;
  height: 59px !important;
  /* text-align: left; */
  font: normal normal 800 32px/0px Nunito Sans !important;
  letter-spacing: 0px;
  text-align: center;
  color: #152d79;
  opacity: 1;
}

.section1 {
  padding: 70px !important;
  margin-right: 32px !important;
  margin-left: 33px !important;
}
}


@media only screen and (max-width: 1300px) {
  
  .img260{
   transform: scale(0.8);
  }
 .upperdiv{
  transform: scale(0.8);
  row-gap: 10px;
 }
 .highspeed2{
   column-gap: 43px;
     padding-top: 36px;
     padding-left: 45px;
 }
 .upperdiv h1 {
     text-align: left;
     font: normal normal 800 39px/45px Nunito Sans;
     letter-spacing: 0px;
     color: #152D79;
 }
 .img261{
   transform: scale(0.8);
 }
 .unlimiteddiv2 {
   display: flex;
   flex-direction: column;
   row-gap: 35px;
   text-align: center;
   align-items: center;
   transform: scale(0.8);
   padding-left: 41px;
 }
 .highspeed3 {
   background: #F5F7FF 0% 0% no-repeat padding-box;
   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 26px;
   padding-top: 7px;
   padding-bottom: 62px;
 }
 }

@media only screen and (max-width: 1100px){

  .slpart1{
      flex-direction: column;
      row-gap: 43px;
      padding-right: 0px;
  }
  .highspeed2{
    padding-left:0px ;
  }
  .upperdiv h1{
    text-align: center;
    font-size: 33px;
  }

  .highspeed2{
    flex-direction: column;
  }

  .highspeed3{
    flex-direction: column;
    row-gap: 62px;
  }

  .unlimiteddiv2{

    row-gap: 13px;
    
  }

  .unlimiteddiv2 h2{
    text-align: center;
    font-size: 33px;
  }
  .unlimiteddiv2 span{
    text-align: center;
    font-size: 30px;
  }

  .unlimiteddiv2 img{
    width: 160px;
    height: 3.33px;
    position: relative;
    bottom: 23px;
    left: 77px;
  }

  .imidiv{

    flex-direction: column;
    row-gap: 20px;
  }
  .img261 {
    width: unset !important;
    margin-right: 0 !important;
}

}

@media only screen and (max-width: 480px) {

  .img259 {
    width: 259px !important;
    height: 266px !important;
    margin-bottom: 63px !important;
    margin-right: 0px !important;

}


.btn-savetalk{
  width: 231px !important;
    height: 56px !important;
    background: 0% 0% no-repeat padding-box padding-box rgb(21, 45, 121);
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    border-radius: 34px !important;
    opacity: 1;
    font-size: 16px !important;
    margin-left: 13% !important;
    margin-right: 20px !important;
}
.slpart {
  background-color: white;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 76px;
}
.img260 {
  width: 289px !important;
  height: 288px !important;
  margin-bottom: 45px;

}
.img260 img{
  width: 100%;
}
.hhh3 {
  width: 100vw !important;
  font: normal normal 800 29px/48px Nunito Sans !important;
  letter-spacing: 0px;
  text-align: center;
  color: #152d79;
  opacity: 1;
}
.g2b{
  display: none;
}
.he1 {
 display: none;

}
.savetalk{
  display: none;
}

.btn-savetalk{
  margin-left: 20px !important;
  margin-right: 242px !important;


}
.section1 {
  padding: 0px !important;
  margin-right: 0px !important;
}
.img261 {
 display: none;
}
.section2 {
  display: contents;
}
.unlimiteddiv2{
  padding-left: 0px;
}
}