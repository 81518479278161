.plans-main {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  opacity: 1;
}

.modalright{

height: auto;
width: 38%;
background:#EE8B3F;
border-top-right-radius: .3rem;
border-bottom-right-radius: .3rem;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
row-gap:40px;

}
.modalright .btn{

  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  /* position: relative;
  top: 60px; */
}

.modalright h1{
  /* position: relative;
  bottom: 100px; */
  color:white;
}
.modalright .innerdiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  color:white;
  /* margin-bottom: 56px; */
  text-align: center;
}
.modalleft{
  width: 70%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 50px;
  row-gap: 10px;
  justify-content: flex-start;
  max-height: 100%;
 /* overflow-y: auto; */
 background: #fff;
}
.modalleft .innerdiv{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  row-gap: 20px;
  color:gray;
  
}
.support-tetx {
  width: 100%;
  height: 226px;
  background: #f5f7ff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.support-trans{
  transform: scale(0.9);
}
.head-h1{
  transform: scale(0.8);
}

.service-p {
  text-align: center;
  font: normal normal 500 26px/61px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
}
.head-p {
  text-align: center;
  font: normal normal 800 46px/35px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  display: contents;
}
.im86 {
  width: 494px;
  height: 13px;
  opacity: 1;
  display: flex;
  margin-top: 14px;
  margin-left: 33px;
}
.price-text {
  display: flex;

  text-align: center;
  align-items: center;
  justify-content: center;
}
.head-h1 {
  text-align: center;
  font: normal normal 800 43px/126px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  opacity: 1;
}

.doll2 {
  height: 42px;
  margin-top: 56px;
  font: normal normal 800 29px/58px Nunito Sans;
  color: #152d79;
  flex-direction: row;
  display: flex;
  align-items: end;
  font: normal normal 800 30px/93px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  opacity: 1;
}
.talk1 {
  margin-left: 21px;
  display: flex;
  justify-content: center;
  margin-top: 13px;
  margin-top: 29px;
}
.l11 {
  width: 252px;
  margin-left: 0%;
  font: normal normal bold 20px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  text-transform: capitalize;
  opacity: 1;
}
.tick2 {
  width: 24px;
  height: 22px;
  opacity: 1;
  margin-right: 9px;
}
.pc {
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: scale(0.8); */
  /* margin-top: -13px; */
}
.gutter {
  margin-left: 40px;
  margin-right: 40px;
}
.gutter > [class^="col-"], .gutter > [class*=" col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

.parice-card2 {
  /* width: 321px; */
  min-width: 315px;
  min-height: 370px;
  height: 680px;
  display: flex;
  margin-right: 14px;
  flex-direction: column;
  display: flex;
  align-items: center;
  position: relative;
  background: #f5f7ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: 5px solid #405ebf;
}

.parice-card2:hover > .btnbasic3{


  background-color:#EE8B3F;
 
}
.parice-card2:hover {
  border: 5px solid #EE8B3F
}


.parice-card3 {
  width: 321px;
  min-height: 370px;
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  position: relative;
  background: #f5f7ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: 5px solid #ee8b3f;
}

.btnbasic3 {
  background: #405ebf 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 0px 21px;
  height: 43px;
  border: none;
  font-size: 24px;
  color: #ffffff;
  font: normal normal 800 15px/35px Nunito Sans;
  position: absolute;
  top: -2px;

}

.btnbasic4 {
  background: #ee8b3f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 196px;
  height: 43px;
  border: none;
  font-size: 24px;
  color: #ffffff;
  font: normal normal 800 15px/36px Nunito Sans;
  position: absolute;
  left: 60px;
  top: -2px;
}
.whatsinclude {
  font: normal normal bold 23px/51px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  margin-right: 64px;
  opacity: 1;
  margin-top: 11px;
}

.showtext {
  width: 637px;
  text-align: center;
  font: normal normal 500 20px/30px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  opacity: 1;
  display: flex;
  text-align: center;
  transform: scale(0.8);
}
.pa1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.network-part {
  height: 441px;
  background: #546cb6 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 30px;
}
.network-heading {
  display: flex;
  justify-content: center;
  height: 164px;
}

.nth {
  width: 448px;
  position: relative;
  text-align: center;
  font: normal normal bold 32px/38px Nunito Sans;
  letter-spacing: 0px;
  margin-top: 45px;
  color: #ffffff;
  display: flex;
  transform: scale(0.9);
}

.iicon-img {
  width: 53px;
  height: 38px;
  opacity: 1;
}

.hed {
  text-align: left;
  font: normal normal bold 15px/21px Nunito Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  width: 143px;
  margin-top: 7px;
  height: 58px;
  text-transform: capitalize;
  opacity: 1;
}
.per {
  width: 190px;
  text-align: left;
  font: normal normal 500 15px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.iicon-head {
  margin-right: 15px;
  border-right: 1px solid #74bfd8;
  opacity: 1;
  padding-right: 20px;
}
.iicon-headl{
  margin-right: 15px;
  opacity: 1;
}
.iicon{
  margin-right: 14px;
}
.main-iccon {
  display: flex;
  justify-content: center;
  margin-top: 23px;
  transform: scale(0.9);
}

p.tagline_para {
  white-space: break-spaces;
  color: #152D79;
  font-size: 12px;
  font-weight: 600;
}

@media only screen and (max-width: 1007px){
  .main-iccon{
    transform: scale(0.7) !important;
  }
  .main-imgdiv{
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 788px){
  .main-iccon{
    transform: scale(0.5) !important;
  }
.main-imgdiv{
  transform: scale(0.5);
  margin-top: -111px;
  margin-bottom: 0px !important;
}
.need-text{
  transform: scale(0.8);
  
}
.modalleft{
  padding: 15px;
  /* max-height: 70vh; */
}
.modalright{
  padding: 10px 5px;
}
.modalright .btn{
  padding: 0;
}
.modalright button{
  font-size: 10px;
}
.modalright h4{
  font-size: 1rem;
}
.modalright h1{
  font-size: 1.7rem;
}
.modalleft h4{
  font-size: 1rem;
}
.modalleft h3{
  font-size: 1.2rem;
}
.modalleft h2{
  font-size: 1.5rem;
}
}
@media only screen and (max-width: 768px){
  .modalleft{
    width: 100%;
    row-gap: 0;
    padding: 5px 45px;
    padding-top: 30px;
  }
  .modalright{
    width: 100%;
    row-gap: 0;
    padding: 10px 0px;
  }
  .modalright h1{
    margin-bottom: 10px;
  }
  .modalright .btn{
    margin-top: 10px;
  }
  .modalright .innerdiv{
    row-gap: 0px;
  }
  .modalleft .innerdiv{
    row-gap: 0px;
  }
  .closeBtn{
    display: block !important;
    padding: 0px 15px;
  }
  .closeBtn span{
    float: right;
    font-size: 40px;
    font-weight: 300;
    color: #000000;
    position: absolute;
    right: 15px;
  }
}
@media only screen and (max-width: 650px) {
  .pc{
    /* transform: scale(0.8); */
  }
  .icons-div{
    transform: scale(0.9);
    margin-right: 0px !important;
  }
  .para-div {
    background: #f5f7ff 0% 0% no-repeat padding-box;
    border-radius: 10px 0px 0px 0px;
    opacity: 1;
    height: 340px !important;
}
.po3 {
  width: 327px !important;
  text-align: center;
  font: normal normal 600 12px/27px Nunito Sans;
  letter-spacing: 0px;
  color: #152d79;
  opacity: 1;
}
}

@media only screen and (max-width: 480px) {
  .buttonplan {
    font-family: "Nunito Sans";
    width: 190px !important;
    height: 47px !important;
    margin-top: 27px !important;
    margin-bottom: 24px;
    background: 0% 0% no-repeat padding-box padding-box rgb(21, 45, 121);
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    border-radius: 34px !important;
    opacity: 1;
    color: rgb(255, 255, 255);
  }
  .nd-text1,.nd-text2,.nd-text3,.nd-text4,.nd-text5,.nd-text6{
    display: none;
  }
  
  .btnbasic3 {
    background: #405ebf 0% 0% no-repeat padding-box;
    border-radius: 5px;
    /* width: 119px; */
    height: 43px;
    border: none;
    font-size: 24px;
    color: #ffffff;
    font: normal normal 800 15px/35px Nunito Sans;
    position: absolute;
    top: -2px;
    /* left: 84px !important; */
  }
  .parice-card3 {
    width: 295px;
    min-height: 337px;
    display: flex;
    margin-right: 14px;
    flex-direction: column;
    display: flex;
    align-items: center;
    position: relative;
    background: #f5f7ff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    border: 5px solid #ee8b3f;
  }
  .btnbasic4 {
    background: #ee8b3f 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 196px;
    height: 43px;
    border: none;
    font-size: 24px;
    color: #ffffff;
    font: normal normal 800 15px/36px Nunito Sans;
    position: absolute;
    left: 53px;
    top: -2px;
  }
  .whatsinclude {
    font: normal normal bold 20px/32px Nunito Sans;
    letter-spacing: 0px;
    color: #152d79;
    margin-right: 68px;
    opacity: 1;
    margin-top: 9px;
  }
  .showtext {
    text-align: center;
    font: normal normal 600 14px/26px Nunito Sans;
    color: #152d79;
    padding: 26px;
    display: flex;
    text-align: center;
  }
  .nth {
    width: 448px;
    position: relative;
    text-align: center;
    font: normal normal bold 21px/28px Nunito Sans;
    margin-top: 39px;
    color: #ffffff;
    padding: 7px;
    display: flex;
}
.im86 {
  width: 64vw !important;
  position: relative;
  bottom: 10px;
  right: 10px;
}

.img866 {
  display: none;
}
.network-part {
  height: 901px !important;
  background: #546cb6 0% 0% no-repeat padding-box;
  opacity: 1;
   /* margin-top: 30px; */
}
.main-iccon {
  display: flex;
    justify-content: center;
    margin-top: -18px !important;
    flex-direction: column;
    transform: none !important;
}
.hed {
  font: normal normal bold 15px/21px Nunito Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  display: flex;
  opacity: 1;
  justify-content: center;
}
.iicon-head {
  margin-right: 0px;
  padding-right: 0px;
  opacity: 1;
  display: flex;
  border: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.iicon-headl{
  margin-right: 0px;
  padding-right: 0px;
  opacity: 1;
  display: flex;
  border: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.hed {
  font: normal normal bold 9px/27px Nunito Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  display: flex;
  opacity: 1;
  justify-content: center;
  margin-bottom: -36px;
}


.per {
    width: 190px;
    text-align: left;
    font: normal normal 600 8px/22px Nunito Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    text-align: center;
    margin-bottom: 26px;
}
p.tagline_para {
  margin-left: 5%;
}
}
@media only screen and (max-width: 350px) {
  .modalright h4{
    font-size: 0.8rem;
  }
  .modalright h1{
    font-size: 1.4rem;
  }
  .modalleft h4{
    font-size: 0.8rem;
  }
  .modalleft h3{
    font-size: 1rem;
  }
  .modalleft h2{
    font-size: 1.3rem;
  }
}
